import { FormControl, MenuItem, Select as MuiSelect } from '@mui/material'

export default function Select(props) {
  const { name, value, onChange, options, disabled, ...other } = props

  return (
    <FormControl fullWidth variant="standard">
      <MuiSelect
        sx={{
          backgroundColor: '#e9e9e9',
          borderRadius: '20px',
          height: '38px',
          border: '1px solid rgba(201, 201, 201, 0.711)',
          paddingLeft: '20px',
          fontSize: '14px',
          fontFamily: 'Nunito',
        }}
        name={name}
        value={value}
        onChange={onChange}
        disableUnderline={true}
        displayEmpty
        disabled={disabled}
        renderValue={value !== '' ? undefined : () => 'Selecciona una Opción'}
        {...other}
      >
        {options?.map(({ id, title }) => (
          <MenuItem value={id} key={id}>
            {title}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
