import { useEffect, useState } from 'react'
import { Form, useForm } from '../UI/Form'
import { Button, Grid, IconButton } from '@mui/material'
import Controls from '../UI/Controls'
import { Add, Clear } from '@mui/icons-material'
import { addWorker, updateWorker } from '../../services/workers'
import Swal from 'sweetalert2'

const initialValues = {
  nombre: '',
}

const WorkersForm = ({ setOpenPopup, update, setUpdate }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  const [area, setArea] = useState('')
  const [puesto, setPuesto] = useState('')
  const [puestosOptions, setPuestosOptions] = useState([])
  const [variablesArray, setVariablesArray] = useState([
    { variable: '-', importe: 0 },
  ])

  const { nombre } = values

  // Definimos los puestos por área
  const puestosPorArea = {
    1: [
      { id: '1', title: 'Encargado de Producción' },
      { id: '2', title: 'Asistente de Producción 1' },
      { id: '3', title: 'Transfer de Producción' },
      { id: '4', title: 'Asistente de Producción 2' },
    ],
    2: [
      { id: '5', title: 'Paletizador 1' },
      { id: '6', title: 'Paletizador 2' },
      { id: '7', title: 'Transfer de Paletizado' },
    ],
    3: [
      { id: '8', title: 'Chofer' },
      { id: '9', title: 'Ayudante de Entregas' },
    ],
    4: [
      { id: '10', title: 'Encargado de Despacho' },
      { id: '11', title: 'Auxiliar de Despacho' },
    ],
  }

  useEffect(() => {
    if (update) {
      const {
        area: updateArea,
        puesto: updatePuesto,
        variablesArray: updateArray,
      } = update

      // Seteamos los valores de nombre, área y puesto del update
      setValues({ nombre: update.nombre })
      setArea(updateArea || '')
      setPuesto(updatePuesto || '')

      // Actualizamos las opciones de puestos y objetosArray
      if (puestosPorArea[updateArea]) {
        setPuestosOptions(puestosPorArea[updateArea])
      }
      setVariablesArray(updateArray || [])
    } else {
      setUpdate(null)
    }
  }, [update])

  const validate = () => {
    let temp = {}

    temp.nombre = (nombre || '').length === 0 ? 'Este campo es requerido' : ''
    temp.area = (area || '').length === 0 ? 'Este campo es requerido' : ''
    temp.puesto = (puesto || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  const handleAreaChange = (e) => {
    const selectedArea = e.target.value
    setArea(selectedArea)

    if (puestosPorArea[selectedArea]) {
      setPuestosOptions(puestosPorArea[selectedArea])
    } else {
      setPuestosOptions([])
    }

    setPuesto('')
  }

  // Manejar el cambio de los inputs del array de objetos
  const handleArrayChange = (index, field, value) => {
    const updatedArray = [...variablesArray]
    updatedArray[index] = {
      ...updatedArray[index],
      [field]: field === 'importe' ? Number(value) : value,
    }
    setVariablesArray(updatedArray)
  }

  // Agregar un nuevo objeto al array
  const addArrayInput = () => {
    setVariablesArray([...variablesArray, { variable: '', importe: 0 }])
  }

  // Eliminar un objeto del array
  const removeArrayInput = (index) => {
    const updatedArray = variablesArray.filter((_, i) => i !== index)
    setVariablesArray(updatedArray)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (validate()) {
      const data = {
        nombre,
        area,
        puesto,
        variablesArray,
      }

      if (update) {
        updateWorker(update.id, nombre, area, puesto, variablesArray)
      } else {
        addWorker(data)
      }
      setValues({})
      setArea('')
      setPuesto('')
      setVariablesArray([])
      setPuestosOptions([])
      setUpdate(null)
      Swal.fire('Hecho', 'Trabajador registrado con éxito', 'success')
      resetForm()

      setOpenPopup(false)
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <label htmlFor="nombre">* Nombre</label>
            <Controls.Input
              id="nombre"
              type="text"
              name="nombre"
              value={nombre}
              onChange={handleInputChange}
              error={errors.nombre}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label htmlFor="area">* Area</label>
            <Controls.Select
              id="area"
              type="text"
              name="area"
              value={area}
              onChange={handleAreaChange}
              options={[
                { id: '', title: 'Selecciona una Opción' },
                { id: '1', title: 'Producción' },
                { id: '2', title: 'Paletizado' },
                { id: '3', title: 'Entregas' },
                { id: '4', title: 'Despacho' },
              ]}
            />
            {errors.area !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.puesto}
              </label>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <label htmlFor="puesto">* Puesto</label>
            <Controls.Select
              id="puesto"
              type="text"
              name="puesto"
              value={puesto}
              onChange={(e) => setPuesto(e.target.value)}
              options={[
                { id: '', title: 'Selecciona una Opción' },
                ...puestosOptions,
              ]}
            />
            {errors.puesto !== '' && (
              <label
                style={{
                  color: '#d32f2f',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: '400',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '0',
                  marginBottom: '0',
                  marginLeft: '20px',
                }}
              >
                {errors.puesto}
              </label>
            )}
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="container">Variables Nominales</label>
            {variablesArray.map((objeto, index) => (
              <Grid
                id="container"
                container
                spacing={0}
                alignItems="center"
                key={index}
                sx={{ marginTop: '5px' }}
              >
                <Grid item xs={5}>
                  <label htmlFor="variable">{`Concepto - ${index + 1}`}</label>
                  <Controls.Input
                    id="variable"
                    type="text"
                    value={objeto.variable}
                    onChange={(e) =>
                      handleArrayChange(index, 'variable', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5}>
                  <label htmlFor="importe">{`Importe - ${index + 1}`}</label>
                  <Controls.Input
                    id="importe"
                    type="number"
                    value={objeto.importe}
                    onChange={(e) =>
                      handleArrayChange(index, 'importe', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    style={{
                      marginTop: '22px',
                      marginLeft: '10px',
                      backgroundColor: '#00544e',
                      color: 'white',
                      height: '38px',
                      width: '38px',
                      '&:hover': {
                        backgroundColor: '#009696',
                        border: '1px solid #00544e',
                      },
                    }}
                    onClick={() => removeArrayInput(index)}
                  >
                    <Clear />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#00544e',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em',
                border: '1px solid #009696',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                width: '100%',
                '&:hover': {
                  backgroundColor: '#009696',
                  border: '1px solid #00544e',
                },
                marginTop: '10px',
                marginBottom: '15px',
              }}
              onClick={addArrayInput}
            >
              Agregar Variable
            </Button>
          </Grid>
        </Grid>
        <input id="submit" className="btn" type="submit" name="submit" />
      </Form>
    </div>
  )
}

export default WorkersForm
