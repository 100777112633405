import { makeStyles, TableBody } from '@material-ui/core'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Slide,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
} from '@mui/material'
import React, { useState } from 'react'
import { useNominas } from '../../services/nomina'
import useTable from '../UI/Table'
import {
  Add,
  Check,
  Close,
  Edit,
  LocalShipping,
  Person,
  PrecisionManufacturing,
  Search,
  Visibility,
  Widgets,
} from '@mui/icons-material'
import Controls from '../UI/Controls'
import {
  cancelNomina,
  checkStatus,
  closeNomina,
  getStatusToString,
} from '../../helpers/nominaFunctions'
import { CustomDialog } from '../UI/Dialog'
import CreateNomina from './createForm'
import ProductionForm from './Production/form'
import PaletForm from './Palets/form'
import LogisticForm from './Logistic/form'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const headCells = [
  { id: 'nomina', label: 'Nomina' },
  { id: 'status', label: 'Status' },
  { id: 'areas', label: 'Areas' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles(() => ({
  toolBar: {
    justifyContent: 'space-between',
  },
  '@media (max-width: 700px)': {
    toolBar: {
      display: 'list-item',
      flexWrap: 'wrap',
    },
    margin: {
      marginTop: '20px',
    },
  },
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
  buttonAdd: {
    left: '5px',
    backgroundColor: '#dec65e',
  },

  '@media (max-width: 750px)': {
    buttonAdd: {
      left: '5px',
      backgroundColor: '#dec65e',
    },
  },
  iconButton: {
    color: 'white',
    height: '30px',
    width: '30px',
    transition: '0.3s all ease',
    marginRight: '5px',
  },
  icon: {
    width: '20px',
    height: '20px',
  },
}))

export const Nominas = () => {
  const [nominas] = useNominas()

  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [produccion, setProduccion] = useState(false)
  const [paletizado, setPaletizado] = useState(false)
  const [logistica, setLogistica] = useState(false)
  const [object, setObject] = useState({})
  const [id, setId] = useState('')

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(nominas, headCells, filterFn)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.textWeek.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '3%' }} className="with-navbar">
        <h2 className="h1">Administración de Nóminas</h2>
      </div>
      <div className="form" style={{ marginBottom: '30px' }}>
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Controls.Input
                variant="standard"
                className={classes.searchInput}
                placeholder="Buscar Registro"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                variant="contained"
                startIcon={<Add />}
                sx={{
                  backgroundColor: '#009696',
                  padding: '12px 15px',
                  height: '40px',
                  fontSize: '1em',
                  border: '1px solid #00544e',
                  borderRadius: '30px',
                  transition: '0.3s all ease',
                  color: 'secondary',
                  '&:hover': {
                    backgroundColor: '#00544e',
                    border: '1px solid #009696',
                  },
                  '@media (max-width 514px)': {
                    marginTop: '20px',
                  },
                }}
                onClick={() => {
                  setOpen(true)
                }}
              >
                Crear Nueva Nómina
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((nomina) => (
              <TableRow key={nomina.id}>
                <TableCell
                  data-label="Nomina: "
                  className={classes.tableCell}
                  style={{ width: '40%' }}
                >
                  {nomina.descripcion}
                </TableCell>
                <TableCell
                  data-label="Estado: "
                  className={classes.tableCell}
                  style={{ width: '40%' }}
                >
                  {getStatusToString(nomina.status)}
                </TableCell>
                <TableCell
                  data-label="Areas: "
                  className={classes.tableCellActions}
                  style={{ width: '20%' }}
                >
                  <Tooltip title="Editar Area de Producción">
                    <IconButton
                      onClick={() => {
                        setProduccion(true)
                        setObject(nomina[1])
                        setId(nomina.id)
                      }}
                      sx={{
                        backgroundColor: '#00544e',
                        '&:hover': {
                          backgroundColor: '#009696',
                          border: '1px solid #00544e',
                        },
                      }}
                      className={classes.iconButton}
                    >
                      <PrecisionManufacturing className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar Area de Paletizado">
                    <IconButton
                      onClick={() => {
                        setPaletizado(true)
                        setObject(nomina[2])
                        setId(nomina.id)
                      }}
                      sx={{
                        backgroundColor: '#00544e',
                        '&:hover': {
                          backgroundColor: '#009696',
                          border: '1px solid #00544e',
                        },
                      }}
                      className={classes.iconButton}
                    >
                      <Widgets className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar Area de Despacho">
                    <IconButton
                      onClick={() => {
                        //setDetails(nomina)
                        //setOpenDetails(true)
                      }}
                      sx={{
                        backgroundColor: '#00544e',
                        '&:hover': {
                          backgroundColor: '#009696',
                          border: '1px solid #00544e',
                        },
                      }}
                      className={classes.iconButton}
                    >
                      <Person className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar Area de Logística">
                    <IconButton
                      onClick={() => {
                        setLogistica(true)
                        setObject(nomina[4])
                        setId(nomina.id)
                      }}
                      sx={{
                        backgroundColor: '#00544e',
                        '&:hover': {
                          backgroundColor: '#009696',
                          border: '1px solid #00544e',
                        },
                      }}
                      className={classes.iconButton}
                    >
                      <LocalShipping className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell
                  data-label="Acciones: "
                  className={classes.tableCellActions}
                  style={{ width: '20%' }}
                >
                  <Grid container spacing={1}>
                    <Tooltip title="Editar Nómina">
                      <IconButton
                        disabled={checkStatus(nomina.status)}
                        onClick={() => {
                          setObject(nomina)
                          setOpen(true)
                        }}
                        sx={{
                          backgroundColor: 'orange',
                          '&:hover': {
                            backgroundColor: 'darkorange',
                            border: '1px solid orange',
                          },
                        }}
                        className={classes.iconButton}
                      >
                        <Edit className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancelar Nómina">
                      <IconButton
                        disabled={checkStatus(nomina.status)}
                        onClick={() => {
                          cancelNomina(nomina.id)
                        }}
                        sx={{
                          backgroundColor: 'red',
                          '&:hover': {
                            backgroundColor: 'darkred',
                            border: '1px solid red',
                          },
                        }}
                        className={classes.iconButton}
                      >
                        <Close className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cerrar Nómina">
                      <IconButton
                        disabled={checkStatus(nomina.status)}
                        onClick={() => {
                          closeNomina(nomina.id)
                        }}
                        sx={{
                          backgroundColor: 'green',
                          '&:hover': {
                            backgroundColor: 'darkgreen',
                            border: '1px solid green',
                          },
                        }}
                        className={classes.iconButton}
                      >
                        <Check className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ver Nómina">
                      <IconButton
                        onClick={() => {
                          //setDetails(nomina)
                          //setOpenDetails(true)
                        }}
                        sx={{
                          backgroundColor: '#00544e',
                          '&:hover': {
                            backgroundColor: '#009696',
                            border: '1px solid #00544e',
                          },
                        }}
                        className={classes.iconButton}
                      >
                        <Visibility className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title="Formulario para Crear Nómina"
          openPopup={open}
          setOpenPopup={setOpen}
          maxWidth="sm"
          onClose={null}
          //fullScreen
          //TransitionComponent={Transition}
        >
          <CreateNomina
            setOpenPopup={setOpen}
            update={object}
            setUpdate={setObject}
          />
        </CustomDialog>
        <CustomDialog
          title="Formulario Area Producción"
          openPopup={produccion}
          setOpenPopup={setProduccion}
          onClose={null}
          fullScreen
          TransitionComponent={Transition}
        >
          <ProductionForm
            setObject={setObject}
            object={object}
            setOpenPopup={setProduccion}
            setId={setId}
            id={id}
          />
        </CustomDialog>
        <CustomDialog
          title="Formulario Area Paletizado"
          openPopup={paletizado}
          setOpenPopup={setPaletizado}
          onClose={null}
          fullScreen
          TransitionComponent={Transition}
        >
          <PaletForm
            setObject={setObject}
            object={object}
            setOpenPopup={setPaletizado}
            setId={setId}
            id={id}
          />
        </CustomDialog>
        <CustomDialog
          title="Formulario Area Logistica"
          openPopup={logistica}
          setOpenPopup={setLogistica}
          onClose={null}
          fullScreen
          TransitionComponent={Transition}
        >
          <LogisticForm
            setObject={setObject}
            object={object}
            setOpenPopup={setLogistica}
            setId={setId}
            id={id}
          />
        </CustomDialog>
      </div>
    </>
  )
}
