import { Grid, Typography } from '@mui/material'

import { nomina } from './object'
import { Box, styled } from '@mui/system'
import { makeStyles } from '@material-ui/core'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import esLocale from 'date-fns/locale/es'
import { Form } from '../UI/Form'
import DateFnsUtils from '@date-io/date-fns'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { createNomina, updateNomina } from '../../services/nomina'

const useStyles = makeStyles(() => ({
  gridItem: {
    display: 'flex',
    justifyContent: 'start',
  },
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const CreateNomina = ({ setOpenPopup, update, setUpdate }) => {
  const classes = useStyles()

  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [nominaObj, setNominaObj] = useState(nomina)

  useEffect(() => {
    if (update) {
      if (update.initialDate) setInitialDate(new Date(update.initialDate))
      if (update.finalDate) setFinalDate(new Date(update.finalDate))
    } else {
      setUpdate(null)
    }
  }, [update, setUpdate])

  const resetData = () => {
    setInitialDate('')
    setFinalDate('')
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (update) {
      updateNomina(update.id, initialDate, finalDate)
    } else {
      createNomina(nominaObj, initialDate, finalDate)
    }

    resetData()
    Swal.fire('Hecho', 'Nómina creada con éxito', 'success')

    setOpenPopup(false)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <NormalText className={classes.gridItem}>Fecha Inicial</NormalText>
            <LocalizationProvider
              adapterLocale={esLocale}
              dateAdapter={DateFnsUtils}
            >
              <MobileDatePicker
                inputFormat="dd/MM/yyyy"
                value={initialDate}
                onChange={(date) => setInitialDate(date)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className="medium-group" style={{ width: '100%' }}>
                      <input
                        className="input-select"
                        ref={inputRef}
                        {...inputProps}
                      ></input>
                    </div>
                  </Box>
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <NormalText className={classes.gridItem}>Fecha Final</NormalText>
            <LocalizationProvider
              adapterLocale={esLocale}
              dateAdapter={DateFnsUtils}
            >
              <MobileDatePicker
                inputFormat="dd/MM/yyyy"
                value={finalDate}
                onChange={(date) => {
                  if (date.getTime() < initialDate.getTime()) {
                    Swal.fire({
                      title: 'Error',
                      text: 'La fecha final no puede ser menor a la fecha inicial',
                      customClass: {
                        container: 'my-swal',
                      },
                      icon: 'info',
                    })
                  } else {
                    setFinalDate(date)
                  }
                }}
                renderInput={({ inputRef, inputProps }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <div className="medium-group" style={{ width: '100%' }}>
                      <input
                        className="input-select"
                        ref={inputRef}
                        {...inputProps}
                      ></input>
                    </div>
                  </Box>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <input id="submit" className="btn" type="submit" name="submit" />
      </Form>
    </>
  )
}

export default CreateNomina
