import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { Form } from '../../UI/Form'
import Controls from '../../UI/Controls'
import { styled } from '@mui/system'
import { makeStyles } from '@material-ui/core'
import { Calculate, Title } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import {
  getSelectBonos,
  getWorkerNameById,
  getWorkersByArea,
  selectLogisticWorkers,
} from '../../../helpers/workersFunctions'
import {
  calculateDriverSalaries,
  getDeliveriesByDriver,
} from '../../../helpers/nominaFunctions'

const useStyles = makeStyles(() => ({
  inputTitle: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'end',
  },
  dateInputTitle: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'end',
  },
  salaryItem: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Subtitle = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '600',
  fontSize: '1.2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const GenerateButton = styled((props) => <Button {...props} />)(() => ({
  backgroundColor: 'orange',
  border: '1px solid darkorange',
  padding: '12px 15px',
  height: '40px',
  fontSize: '1em',
  borderRadius: '30px',
  transition: '0.3s all ease',
  color: 'white',
  width: '100%',
  '&:hover': {
    backgroundColor: 'darkorange',
    border: '1px solid orange',
  },
  marginTop: '10px',
  marginBottom: '15px',
}))

const BonoSelect = styled((props) => <Select {...props} />)(() => ({
  width: '100%',
  backgroundColor: '#e9e9e9',
  borderRadius: '20px',
  height: '38px',
  border: '1px solid rgba(201, 201, 201, 0.711)',
  paddingLeft: '20px',
  fontSize: '14px',
  fontFamily: 'Nunito',
  '&:focus': {
    backgroundColor: 'transparent', // Esto elimina el color de fondo cuando está seleccionado
    border: 'none', // También puedes eliminar el borde si es necesario
  },
}))

const trabajador = {
  diasLaborados: '',
  pzsCargadas: '',
  horasExtra: '',
  salarioSemanal: '',
  trabajador: {
    id: '',
    nombre: '',
  },
  bonos: [],
  viajes: {
    kenworth: '',
    camion: '',
    doble: '',
    nissan: '',
  },
}

const LogisticForm = ({ setObject, object, setOpenPopup, setId, id }) => {
  let workers = selectLogisticWorkers()

  const [tempObject, setTempObject] = useState([])

  const classes = useStyles()

  useEffect(() => {
    if (object && tempObject.length === 0) {
      setTempObject(object)
    } else if (tempObject.length === 0) {
      const initialData = workers.map((worker) => ({
        ...trabajador,
        trabajador: {
          id: worker.id,
          nombre: worker.title,
        },
      }))
      setTempObject(initialData)
    }
  }, [object, workers])

  const handleGenerateSalary = (workerData, index) => {
    try {
      // 1. Validaciones previas para asegurar que `workerData` es válido
      if (!workerData || !workerData.trabajador || !workerData.trabajador.id) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Datos del trabajador incompletos o inválidos.',
        })
        return
      }

      // Validación de que `id` está presente
      if (!id) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'ID de la nómina no disponible.',
        })
        return
      }

      // Crear una copia profunda de `workerData` para evitar referencias compartidas
      const workerDataCopy = JSON.parse(JSON.stringify(workerData))

      // 2. Llamada a `calculateDriverSalaries` con `workerData` e `id`
      const updatedWorkerData = getDeliveriesByDriver(workerDataCopy, id)

      // Validación de que `calculateDriverSalaries` ha retornado datos válidos
      if (
        !updatedWorkerData ||
        typeof updatedWorkerData.salarioSemanal === 'undefined'
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error en el cálculo del salario. Intenta nuevamente.',
        })
        return
      }

      // 3. Actualización de `tempObject` con el nodo específico
      setTempObject((prevTempObject) =>
        prevTempObject.map((item) =>
          item.trabajador.id === workerData.trabajador.id
            ? { ...item, ...updatedWorkerData }
            : item
        )
      )

      // Mensaje de éxito si todo ha salido bien
      Swal.fire({
        icon: 'success',
        title: 'Salario Generado',
        text: `Salario calculado exitosamente para ${workerData.trabajador.nombre}.`,
      })
    } catch (error) {
      // 4. Manejo de errores
      Swal.fire({
        icon: 'error',
        title: 'Error inesperado',
        text: `Ocurrió un error: ${error.message}.`,
      })
      console.error('Error en handleGenerateSalary:', error)
    }

    workerData = null
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
        width: '100%',
      }}
    >
      <Form style={{ width: '100%' }}>
        <Grid container spacing={1}>
          {tempObject.map((worker, index) => (
            <Grid container item md={12} spacing={1} key={worker.trabajador.id}>
              <Grid item md={12}>
                <Subtitle>Trabajador {worker.trabajador.nombre}</Subtitle>
              </Grid>

              <Grid item md={1}>
                <NormalText>Días Laborados</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`diasLaborados-${index}`}
                  type="number"
                  min={0}
                  max={6}
                  value={worker.diasLaborados}
                  onChange={(e) =>
                    setTempObject((prev) => {
                      const updated = [...prev]
                      updated[index].diasLaborados = e.target.value
                      return updated
                    })
                  }
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Horas Extra</NormalText>
              </Grid>
              <Grid item md={3}>
                <input
                  className="input-select"
                  name={`horasExtra-${index}`}
                  type="number"
                  max={10}
                  value={worker.horasExtra}
                  onChange={(e) =>
                    setTempObject((prev) => {
                      const updated = [...prev]
                      updated[index].horasExtra = e.target.value
                      return updated
                    })
                  }
                />
              </Grid>

              <Grid item md={1}>
                <NormalText>Bonos</NormalText>
              </Grid>
              <Grid item md={3}>
                <BonoSelect
                  name={`bonos-${index}`}
                  multiple
                  value={worker.bonos}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e
                    setTempObject((prev) => {
                      const updated = [...prev]
                      updated[index].bonos =
                        typeof value === 'string' ? value.split(',') : value
                      return updated
                    })
                  }}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {getSelectBonos(worker.trabajador.id).map((item) => (
                    <MenuItem key={item.id} value={item.title}>
                      <Checkbox
                        checked={worker.bonos.indexOf(item.title) > -1}
                      />
                      <ListItemText primary={item.title} />
                    </MenuItem>
                  ))}
                </BonoSelect>
              </Grid>

              <Grid item md={8} />
              <Grid item md={4}>
                <GenerateButton
                  variant="contained"
                  startIcon={<Calculate />}
                  onClick={() => {
                    handleGenerateSalary(worker, index)
                  }}
                >
                  Generar Salario
                </GenerateButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <input id="submit" className="btn" type="submit" name="submit" />
      </Form>
    </div>
  )
}

export default LogisticForm

{
  /* <Grid container item md={12} spacing={1}>
<Grid item md={12}>
              <Subtitle>Trabajador</Subtitle>{' '}
            </Grid>
            <Grid item md={2}>
              <NormalText>Trabajador</NormalText>
            </Grid>
            <Grid item md={4}>
              <Controls.Select
                name="trabajador"
                value={''}
                onChange={() => {}}
                options={[
                  { id: '-', title: 'Selecciona una opción' },
                  ...workers,
                ]}
              />
            </Grid>
            <Grid item md={2}>
              <NormalText>Días Laborados</NormalText>
            </Grid>
            <Grid item md={4}>
              <Controls.Input
                name="diasLaborados"
                type="number"
                min={0}
                max={6}
                value={''}
                onChange={
                  (e) => {}
                  //handleInputChange(index, 'diasLaborados', e.target.value)
                }
              />
            </Grid>
            <Grid item md={2}>
              <NormalText>Horas Extra</NormalText>
            </Grid>
            <Grid item md={4}>
              <Controls.Input
                name="horasExtra"
                type="number"
                min={0}
                max={10}
                value={''}
                onChange={
                  (e) => {}
                  //handleInputChange(index, 'diasLaborados', e.target.value)
                }
              />
            </Grid>
            <Grid item md={2}>
              <NormalText>Bonos</NormalText>
            </Grid>
            <Grid item md={4}>
              <BonoSelect
                name="bonos"
                //value={chofer.bonos || []}
                multiple
                //onChange={(event) => handleBonoChange(index, event)}
                renderValue={(selected) => selected.join(', ')}
              >
                {/*getSelectBonos(chofer.id).map((item) => (
                  <MenuItem key={item.id} value={item.title}>
                    <Checkbox checked={chofer.bonos.indexOf(item.title) > -1} />
                    <ListItemText primary={item.title} />
                  </MenuItem>
                ))
              </BonoSelect>
            </Grid>
            <Grid item md={8} />
            <Grid item md={4}>
              <GenerateButton
                variant="contained"
                startIcon={<Calculate />}
                onClick={() => {
                  //handleGenerateSalary(chofer)
                }}
              >
                Generar Salario
              </GenerateButton>
            </Grid>
          </Grid> */
}
