import React, { useLayoutEffect } from 'react'

import { addClient, updateClient } from '../../services/clients'
import { useForm, Form } from '../UI/Form'

import Swal from 'sweetalert2'
import Controls from '../UI/Controls'

const initialValues = {
  calle: '',
  colonia: '',
  id: '',
  nombre: '',
  numero: '',
  observaciones: '',
  poblacion: '',
  referencia: '',
  nc: '',
  municipo: '',
  telefono: '',
}

const ClientesForm = ({ setOpenPopup, update, flag, setFlag, id }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  const {
    calle,
    colonia,
    nc,
    nombre,
    numero,
    observaciones,
    poblacion,
    referencia,
    municipio,
    telefono,
  } = values

  const validate = () => {
    let temp = {}

    temp.nombre =
      (nombre || '').length === 0
        ? 'Este campo es requerido'
        : !/^[a-zA-ZÀ-ÿñÑ\s]*$/.test(nombre)
        ? 'No se aceptan caracteres especiales'
        : nombre.length < 4
        ? 'El nombre debe tener al menos 4 caracteres'
        : ''
    temp.calle = (calle || '').length === 0 ? 'Este campo es requerido' : ''
    temp.numero = (numero || '').length === 0 ? 'Este campo es requerido' : ''
    temp.colonia = (colonia || '').length === 0 ? 'Este campo es requerido' : ''
    temp.poblacion =
      (poblacion || '').length === 0 ? 'Este campo es requerido' : ''
    temp.referencia =
      (referencia || '').length === 0 ? 'Este campo es requerido' : ''
    temp.municipio =
      (municipio || '').length === 0 ? 'Este campo es requeido' : ''
    temp.telefono =
      (telefono || '').length === 0
        ? 'Este campo es requerido'
        : !/^[0-9\s]*$/.test(telefono)
        ? 'Solo se aceptan números'
        : ''

    setErrors({
      ...temp,
    })

    return Object.values(temp).every((x) => x === '')
  }

  useLayoutEffect(() => {
    if (update !== undefined) {
      setValues(update)
    }
  }, [setValues, update, flag])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (flag === false) {
        addClient(
          calle,
          colonia,
          id,
          nombre,
          numero,
          observaciones,
          poblacion,
          referencia,
          municipio,
          telefono
        )
      } else {
        updateClient(
          update.id,
          calle,
          colonia,
          nc,
          nombre,
          numero,
          observaciones,
          poblacion,
          referencia,
          municipio,
          telefono
        )
      }
      resetForm()
      setValues({})
      setFlag(false)
      setOpenPopup(false)
      Swal.fire('Hecho', 'Cliente registrado con éxito', 'success')
    }
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        display: 'flex',
        marginBottom: '10px',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="large-group">
          <div className="micro-group">
            <label>Id</label>
            <label>
              {' '}
              <br />
              {nc || id}
            </label>
          </div>
          <div className="medium-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="nombre">* Nombre</label>
            <Controls.Input
              id="nombre"
              type="text"
              name="nombre"
              value={nombre}
              onChange={handleInputChange}
              error={errors.nombre}
            />
          </div>
          <div className="small-group" style={{ marginBottom: '20px' }}>
            <label htmlFor="telefono">* Telefono</label>
            <Controls.Input
              id="telefono"
              type="text"
              name="telefono"
              value={telefono}
              onChange={handleInputChange}
              error={errors.telefono}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label htmlFor="calle">* Domicilio</label>
            <Controls.Input
              id="calle"
              type="text"
              name="calle"
              value={calle}
              onChange={handleInputChange}
              error={errors.calle}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label htmlFor="numero">* Número</label>
            <Controls.Input
              id="numero"
              type="text"
              name="numero"
              value={numero}
              onChange={handleInputChange}
              error={errors.numero}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label htmlFor="colonia">* Colonia</label>
            <Controls.Input
              id="colonia"
              type="text"
              name="colonia"
              value={colonia}
              onChange={handleInputChange}
              error={errors.colonia}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label htmlFor="poblacion">* Población</label>
            <Controls.Input
              id="poblacion"
              type="text"
              name="poblacion"
              value={poblacion}
              onChange={handleInputChange}
              error={errors.poblacion}
            />
          </div>
          <div className="micro-group" style={{ marginTop: '20px' }}>
            <label htmlFor="municipio">* Municipio</label>
            <Controls.Input
              id="municipio"
              type="text"
              name="municipio"
              value={municipio}
              onChange={handleInputChange}
              error={errors.municipio}
            />
          </div>
          <div className="medium-group" style={{ marginTop: '20px' }}>
            <div className="textarea-div">
              <label htmlFor="observaciones">Observaciones</label>
              <Controls.TextArea
                id="observaciones"
                type="text"
                name="observaciones"
                value={observaciones}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="medium-group">
            <div className="textarea-div" style={{ marginTop: '20px' }}>
              <label htmlFor="referencia">* Referencia</label>
              <Controls.TextArea
                id="referencia"
                type="text"
                name="referencia"
                value={referencia}
                onChange={handleInputChange}
              />
              {errors.referencia !== '' && (
                <label
                  style={{
                    color: '#d32f2f',
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: '400',
                    fontSize: '0.75rem',
                    lineHeight: '1.66',
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '0',
                    marginBottom: '0',
                    marginLeft: '20px',
                  }}
                >
                  {errors.referencia}
                </label>
              )}
            </div>
          </div>
          <input
            id="submit"
            className="btn"
            type="submit"
            name="submit"
            style={{ marginTop: '20px' }}
          />
        </div>
      </Form>
    </div>
  )
}

export default ClientesForm
