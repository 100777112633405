import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import {
  Add,
  Search,
  Edit,
  Visibility,
  Delete,
  LocationOn,
} from '@mui/icons-material'

import useTable from '../UI/Table'

import { useState } from 'react'
import LugaresForm from './form'
import { deletePlace, usePlaces } from '../../services/places'
import { CustomDialog } from '../UI/Dialog'
import Swal from 'sweetalert2'
import { PlaceDetails } from './details'
import Controls from '../UI/Controls'

const headCells = [
  { id: 'nombre', label: 'Nombre' },
  { id: 'zona', label: 'Zona' },
  { id: 'distancia', label: 'Distancia' },
  { id: 'tiempos', label: 'Tiempos Estimados' },
  { id: 'camino', label: 'Camino Accidentado' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles(() => ({
  toolBar: {
    justifyContent: 'space-between',
  },
  '@media (max-width: 700px)': {
    toolBar: {
      display: 'list-item',
      flexWrap: 'wrap',
    },
    margin: {
      marginTop: '20px',
    },
  },
  searchInput: {
    width: '100%',
    marginRight: '5%',
  },
  buttonAdd: {
    left: '5px',
    backgroundColor: '#dec65e',
  },

  '@media (max-width: 750px)': {
    buttonAdd: {
      left: '5px',
      backgroundColor: '#dec65e',
    },
  },
}))

export const Places = () => {
  const [places] = usePlaces()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(places, headCells, filterFn)

  const [open, setOpen] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)
  const [update, setUpdate] = useState({})
  const [details, setDetails] = useState({})
  const [flag, setFlag] = useState(false)

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.nombre.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  return (
    <>
      <div style={{ marginTop: '3%' }} className="with-navbar">
        <h2 className="h1">Lugares</h2>
      </div>
      <div className="form" style={{ marginBottom: '30px' }}>
        <Toolbar className={classes.toolBar}>
          <div
            className="medium-group"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Controls.Input
              variant="standard"
              className={classes.searchInput}
              placeholder="Buscar Lugar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
          <div
            className="medium-group"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
              }}
              onClick={() => {
                setOpen(true)
                setUpdate({})
              }}
            >
              Agregar
            </Button>
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((place) => (
              <TableRow key={place.id}>
                <TableCell
                  data-label="Nombre:"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {place.nombre}
                </TableCell>
                <TableCell
                  data-label="Zona:"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {place.zona}
                </TableCell>
                <TableCell
                  data-label="Distancia:"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {place.distancia}
                </TableCell>
                <TableCell
                  data-label="Tiempos:"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {'Camioneta Doble: ' + place.tiempoDoble + ' Horas'} <br />
                  {'Camión: ' + place.tiempoCamion + ' Horas'} <br />
                  {'Torton: ' + place.tiempoTorton + ' Horas'} <br />
                </TableCell>
                <TableCell
                  data-label="Camino Accidentado:"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {place.camino}
                </TableCell>
                <TableCell data-label="Acciones:">
                  <IconButton
                    onClick={() => {
                      setUpdate(place)
                      setFlag(true)
                      setOpen(true)
                    }}
                    sx={{
                      backgroundColor: 'orange',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkorange',
                        border: '1px solid orange',
                      },
                    }}
                  >
                    <Edit sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      Swal.fire({
                        title: '¿Estas seguro de eliminar este registro?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'Sí',
                        denyButtonText: 'No',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          deletePlace(place.id)
                          Swal.fire(
                            'Hecho',
                            'Registro eliminado exitosamente',
                            'success'
                          )
                        } else if (result.isDenied) {
                          Swal.fire('Operación abortada', '', 'info')
                        }
                      })
                    }}
                    sx={{
                      backgroundColor: 'red',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkred',
                        border: '1px solid red',
                      },
                    }}
                  >
                    <Delete sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setDetails(place)
                      setFlag(true)
                      setOpenDetails(true)
                    }}
                    sx={{
                      backgroundColor: '#00544e',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: '#009696',
                        border: '1px solid #00544e',
                      },
                    }}
                  >
                    <Visibility sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      window.open(place.ubicacion)
                    }}
                    sx={{
                      backgroundColor: '#00544e',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      '&:hover': {
                        backgroundColor: '#009696',
                        border: '1px solid #00544e',
                      },
                    }}
                  >
                    <LocationOn sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title="Formulario Lugares"
          openPopup={open}
          setOpenPopup={setOpen}
          maxWidth="md"
        >
          <LugaresForm
            setOpenPopup={setOpen}
            update={update}
            flag={flag}
            setFlag={setFlag}
          />
        </CustomDialog>
        <CustomDialog
          title="Información Lugares"
          openPopup={openDetails}
          setOpenPopup={setOpenDetails}
          maxWidth="sm"
        >
          <PlaceDetails setOpenPopup={setOpenDetails} details={details} />
        </CustomDialog>
      </div>
    </>
  )
}
