import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'

import { Close } from '@mui/icons-material'

export const CustomDialog = (props) => {
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
    maxWidth,
    onClose,
    ...other
  } = props

  return (
    <Dialog
      open={openPopup}
      maxWidth={maxWidth}
      fullWidth={true}
      sx={{ zIndex: '100', mt: '4%' }}
      disableEnforceFocus
      disableAutoFocus
      {...other}
    >
      <DialogTitle>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            onClick={() => {
              setOpenPopup(false)
              if (onClose !== null) {
                onClose()
              }
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}
