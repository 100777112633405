import logo from '../../../assets/bg-logo.svg'

import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <footer className="footer-distributed">
        <div className="footer-left">
          <div className="footer-distributed_logo-container">
            <img
              src={logo}
              className="footer-distributed_logo"
              alt="Logo Preconsa"
            />
          </div>

          <p className="footer-company-name">
            Materiales Preconsa S.A. de C.V. © 2024
          </p>
        </div>
      </footer>
    )
  }
}
