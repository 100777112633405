import { useForm } from '../../../../hooks/useForm'
import { useDispatch } from 'react-redux'
import { loginWithEmailPassword } from '../../../../redux/actions/auth'

import logo from '../../../../assets/bg-logo.svg'

export const Login = () => {
  const dispatch = useDispatch()

  const [formValues, handleInputChange] = useForm({
    email: '',
    password: '',
  })

  const { email, password } = formValues

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(loginWithEmailPassword(email, password))
  }

  return (
    <div
      className="login"
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
      }}
    >
      <div className="loginAside">
        <div className="loginForm">
          <div className="formLogoContainer">
            <img
              src={logo}
              className="formLogo"
              alt="Logo Materiales Preconsa"
            />
          </div>

          <div className="formCenter">
            <form className="formFields" onSubmit={handleLogin}>
              <div className="formField">
                <input
                  className="formFieldInput"
                  type="text"
                  name="email"
                  placeholder="Ingresa tu Correo Electronico"
                  autoComplete="off"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formField">
                <input
                  className="formFieldInput"
                  type="password"
                  name="password"
                  placeholder="Ingresa tu Contraseña"
                  value={password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="formField">
                <button className="formFieldButton" type="submit">
                  Iniciar Sesión
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
