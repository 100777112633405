export const filterDeliveries = (List) => {
  const deliveriesList = []
  const filterOnlyPendientDeliveries = List.filter(
    (item) =>
      item.estado !== 'Envío Completado' && item.estado !== 'En Tránsito'
  )

  filterOnlyPendientDeliveries.forEach(function (item) {
    deliveriesList.push(item)
  })

  const deliveriesSorted = deliveriesList.sort((p1, p2) =>
    p1.date > p2.date ? 1 : p1.date < p2.date ? -1 : 0
  )

  return deliveriesSorted
}

export const isWithinDateRange = (nomina, deliveriesItem) => {
  // Convertir las fechas a formato ISO y extraer solo la fecha (YYYY-MM-DD)
  const initialDate = new Date(nomina.initialDate).toISOString().split('T')[0]
  const finalDate = new Date(nomina.finalDate).toISOString().split('T')[0]
  const deliveryDate = new Date(deliveriesItem.date).toISOString().split('T')[0]

  // Comprobar que deliveryDate esté en el rango de fechas
  return deliveryDate >= initialDate && deliveryDate <= finalDate
}
