import React from 'react'
import { useDispatch } from 'react-redux'

import { logout } from '../../../redux/actions/auth'

import logo from './../../../assets/bg-logo.svg'
import { Button } from '@mui/material'
import { Logout } from '@mui/icons-material'

export const NavbarAdmin = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <nav>
      <img id="logo" src={logo} height="50rem" alt="Logo Preconsa"></img>

      <label htmlFor="drop" className="toggle">
        Menu
      </label>
      <input type="checkbox" id="drop" />
      <ul className="menu">
        <li>
          <a href="/info" style={{ lineHeight: '60px' }}>
            Inicio
          </a>
        </li>
        <li>
          <label htmlFor="drop-1" className="toggle">
            Servicios a Domicilio +
          </label>
          <a href="/calculator">Servicios a Domicilio</a>
          <input type="checkbox" id="drop-1" />
          <ul>
            <li>
              <a href="/calculator">Calcular Servicio</a>
            </li>
            <li>
              <a href="/clients">Clientes</a>
            </li>
            <li>
              <a href="/places">Lugares</a>
            </li>
            <li>
              <a href="/cost">Costos</a>
            </li>
          </ul>
        </li>

        <li>
          <a href="/deliveries" style={{ lineHeight: '60px' }}>
            Entregas a Domicilio
          </a>
        </li>
        <li>
          <label htmlFor="drop-2" className="toggle">
            Recursos Humanos +
          </label>
          <a href="/nominas">Recursos Humanos</a>
          <input type="checkbox" id="drop-2" />
          <ul>
            <li>
              <a href="/nominas">Nóminas</a>
            </li>
            <li>
              <a href="/workers">Trabajadores</a>
            </li>
          </ul>
        </li>
        <li>
          <label htmlFor="drop-3" className="toggle">
            Otros +
          </label>
          <a href="/info">Otros</a>
          <input type="checkbox" id="drop-3" />
          <ul>
            <li>
              <a href="/messages">Mensajes</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
          </ul>
        </li>
        <li style={{ height: '100%' }}>
          <Button
            variant="contained"
            startIcon={<Logout />}
            onClick={handleLogout}
            sx={{
              background: 'transparent',
              border: 'none',
              borderRadius: '0',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#DEC65E',
                border: 'none',
                boxShadow: 'none',
              },
              width: '100%',
              lineHeight: '48px',
              //height: '100%',
            }}
          >
            Cerrar Sesión
          </Button>
        </li>
      </ul>
    </nav>
  )
}
