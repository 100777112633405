import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  IconButton,
  TextField,
  Button,
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import {
  Add,
  Search,
  Visibility,
  Print,
  RequestQuote,
  Warning,
  Close,
} from '@mui/icons-material'

import useTable from '../UI/Table'
import { CustomDialog } from '../UI/Dialog'

import { useLayoutEffect, useState } from 'react'

import { FetchServices } from '../../services/services'
import { useClients } from '../../services/clients'
import { usePlaces } from '../../services/places'

import { ServiceDetails } from './details'
import ServiceForm from './form'
import { useCost } from '../../services/cost'

import { auth } from '../../configs/firebase'
import { generateTicket } from '../../services/reports'
import { Cash } from './cash'
import { getDatabase, update, ref } from 'firebase/database'
import Swal from 'sweetalert2'

const headCells = [
  { id: 'folio', label: 'Folio' },
  { id: 'fecha', label: 'Fecha' },
  { id: 'cliente', label: 'Cliente' },
  { id: 'destino', label: 'Destino' },
  { id: 'importe', label: 'Importe' },
  { id: 'estado', label: 'Estado' },
  { id: 'acciones', label: 'Acciones' },
]

const useStyles = makeStyles(() => ({
  toolBar: {
    justifyContent: 'space-between',
  },
  '@media (max-width: 700px)': {
    toolBar: {
      display: 'list-item',
      flexWrap: 'wrap',
    },
    margin: {
      marginTop: '20px',
    },
  },
}))

export const Calculator = () => {
  const [services] = FetchServices()
  const [clients] = useClients()
  const [places] = usePlaces()
  const [cost] = useCost()

  const classes = useStyles()

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(services, headCells, filterFn)

  const [open, setOpen] = useState(false)
  const [openCash, setOpenCash] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)

  const [details, setDetails] = useState({})

  const [disabled, setDisabled] = useState(true)

  useLayoutEffect(() => {
    if (
      auth.currentUser.email === 'diegofs117@outlook.com' ||
      auth.currentUser.email === 'mat_preconsa@outlook.com' ||
      auth.currentUser.email === 'mat.preconsa_mauricio@hotmail.com'
    ) {
      setDisabled(false)
    }
  }, [setDisabled])

  const handleSearch = ({ target }) => {
    const { value } = target
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items
        else
          return items.filter((x) =>
            x.cliente.toLowerCase().includes(value.toLowerCase())
          )
      },
    })
  }

  const cancelService = (id) => {
    Swal.fire({
      icon: 'question',
      title: '¿Estas seguro de cancelar este ticket',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí, estoy seguro',
      denyButtonText: 'Cancelar',
      confirmButtonColor: '#28A745',
      customClass: {
        container: 'my-swal',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const db = getDatabase()

        update(ref(db, `fletes/${id}/`), {
          estado: 'Cancelada',
        })
        Swal.fire({
          title: 'Hecho',
          icon: 'success',
          text: 'Ticket cancelado exitosamente',
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 1000,
          customClass: {
            container: 'my-swal',
          },
        })
      } else if (result.isDenied) {
        Swal.fire({
          title: 'Operación abortada',
          icon: 'info',
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 1000,
          customClass: {
            container: 'my-swal',
          },
        })
      }
    })
  }

  return (
    <>
      <div style={{ marginTop: '3%' }} className="with-navbar">
        <h2 className="h1">Servicios a Domicilio</h2>
      </div>
      <div className="form" style={{ marginTop: '15px', marginBottom: '30px' }}>
        <Toolbar className={classes.toolBar}>
          <div className="small-group">
            <TextField
              variant="standard"
              className={classes.searchInput}
              sx={{
                height: '40px',
                borderRadius: '25px',
                backgroundColor: '#e9e9e9',
                fontSize: '14px',
                padding: '5px',
              }}
              placeholder="Buscar Servicio"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginLeft: '10px' }}>
                    <Search />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={handleSearch}
            />
          </div>
          <div className="small-group">
            <Button
              variant="contained"
              startIcon={<RequestQuote />}
              disabled={disabled}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
              }}
              onClick={() => {
                setOpenCash(true)
              }}
            >
              Caja
            </Button>
          </div>
          <div className="small-group">
            <Button
              variant="contained"
              startIcon={<Add />}
              sx={{
                backgroundColor: '#009696',
                padding: '12px 15px',
                height: '40px',
                fontSize: '1em;',
                border: '1px solid #00544e',
                borderRadius: '30px',
                transition: '0.3s all ease',
                color: 'secondary',
                '&:hover': {
                  backgroundColor: '#00544e',
                  border: '1px solid #009696',
                },
              }}
              onClick={() => {
                setOpen(true)
              }}
            >
              Agregar
            </Button>
          </div>
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordAfterPagingAndSorting().map((service) => (
              <TableRow key={service.id}>
                <TableCell
                  data-label="Folio"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {service.folio}
                </TableCell>
                <TableCell
                  data-label="Fecha"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {service.fecha}
                </TableCell>
                <TableCell
                  data-label="Cliente"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {service.cliente}
                </TableCell>
                <TableCell
                  data-label="Destino"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {service.lugar}
                </TableCell>
                <TableCell
                  data-label="Importe"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  $ {service.importe}
                </TableCell>
                <TableCell
                  data-label="Estado"
                  style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                >
                  {service.estado}
                </TableCell>
                <TableCell data-label="Acciones">
                  {service.estado === 'A crédito' && (
                    <IconButton
                      sx={{
                        backgroundColor: 'darkorange',
                        color: 'white',
                        height: '30px',
                        width: '30px',
                        transition: '0.3s all ease',
                        marginRight: '5px',
                        '&:hover': {
                          backgroundColor: 'darkorange',
                          border: '1px solid orange',
                        },
                      }}
                    >
                      <Warning sx={{ width: '20px', height: '20px' }} />
                    </IconButton>
                  )}

                  <IconButton
                    onClick={() => {
                      cancelService(service.id)
                    }}
                    disabled={disabled}
                    sx={{
                      backgroundColor: 'red',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: 'darkred',
                        border: '1px solid red',
                      },
                    }}
                  >
                    <Close sx={{ width: '20px', height: '20px' }} />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      setDetails(service)
                      setOpenDetails(true)
                    }}
                    sx={{
                      backgroundColor: '#00544e',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      marginRight: '5px',
                      '&:hover': {
                        backgroundColor: '#009696',
                        border: '1px solid #00544e',
                      },
                    }}
                  >
                    <Visibility sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      generateTicket(service)
                    }}
                    sx={{
                      backgroundColor: '#00544e',
                      color: 'white',
                      height: '30px',
                      width: '30px',
                      transition: '0.3s all ease',
                      '&:hover': {
                        backgroundColor: '#009696',
                        border: '1px solid #00544e',
                      },
                    }}
                  >
                    <Print sx={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <CustomDialog
          title="Formulario Servicios a Domicilio"
          openPopup={open}
          setOpenPopup={setOpen}
          maxWidth="md"
        >
          <ServiceForm
            setOpenPopup={setOpen}
            clients={clients}
            places={places}
            cost={cost}
            id={services.length + 1}
          />
        </CustomDialog>
        <CustomDialog
          title="Informacion Servicios a Domicilio"
          openPopup={openDetails}
          setOpenPopup={setOpenDetails}
          maxWidth="md"
        >
          <ServiceDetails setOpenPopup={setOpenDetails} details={details} />
        </CustomDialog>
        <CustomDialog
          title="Caja"
          openPopup={openCash}
          setOpenPopup={setOpenCash}
          sx={{ width: '100%' }}
          maxWidth="md"
        >
          <Cash setOpenPopup={setOpenCash} />
        </CustomDialog>
      </div>
    </>
  )
}
